<route>
{
  "meta": {
    "permission": [
      "supplier.view_supplier"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <v-tabs
      color="primary"
      v-model="tabs"
      class="mb-2"
      next-icon="fa-chevron-right"
      prev-icon="fa-chevron-left"
      centered
      show-arrows
      :icons-and-text="$vuetify.breakpoint.smAndUp"
    >
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tab href="#tab-active">
        <span
          class="mt-2"
          v-if="$vuetify.breakpoint.smAndUp"
          v-text="$t('active')"
        ></span>
        <v-icon :medium="$vuetify.breakpoint.smAndUp" dark
          >fas fa-check-double</v-icon
        >
      </v-tab>
      <v-tab href="#tab-inactive">
        <span
          class="mt-2"
          v-text="$t('inactive')"
          v-if="$vuetify.breakpoint.smAndUp"
        ></span>
        <v-icon :medium="$vuetify.breakpoint.smAndUp" dark
          >fa-times-circle</v-icon
        >
      </v-tab>
    </v-tabs>
    <history
      ref="history"
      v-model="dialog"
      :to-edit="toEdit"
      :type="'supplier'"
      @done="reload = true"
    />
    <i-table
      api="supplier"
      app="supplier.supplier"
      :title="$tc('supplier', 2)"
      :headers="headers"
      :otherPermission="supplierBtn.terms"
      @click:create="changeRoute({ pk: 'create' })"
      @click:edit="changeRouteEdit"
      :opt="{
        active: this.tabs === 'tab-active' ? true : false,
        company_id: this.firstCompany
      }"
      :otherBtn="supplierBtn.product"
    >
      <template v-slot:otherBtn="{ item }">
        <i-btn
          :title="$tc('history_customer', 1)"
          outlined
          classes="mx-1"
          icon="fas fa-chart-pie"
          color="info"
          @click="showHistory(item)"
        />
      </template>
    </i-table>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import history from '../../components/history/modal.vue'
export default {
  components: {
    history
  },
  data() {
    return {
      tabs: 'tab-active',
      reload: false,
      dialog: false,
      toEdit: null,
      firstCompany: 0
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('company_name'),
          value: 'company_name'
        },
        {
          text: this.$t('first_name'),
          value: 'first_name'
        },
        {
          text: this.$t('last_name'),
          value: 'last_name'
        },
        {
          text: this.$tc('email', 1),
          value: 'email'
        },
        {
          text: this.$t('phone_number'),
          value: 'phone'
        },
        {
          text:
            this.supplierBtn.edit ||
            this.supplierBtn.delete ||
            this.supplierBtn.product
              ? this.$t('action')
              : '',
          value:
            this.supplierBtn.edit ||
            this.supplierBtn.delete ||
            this.supplierBtn.product
              ? 'actions'
              : ''
        }
      ]
    },
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin',
      myCompanies: 'session/myCompanies'
    }),
    supplierBtn() {
      return {
        delete:
          this.getPermissions([`supplier.delete_supplier`]) || this.isAdmin,
        edit: this.getPermissions([`supplier.change_supplier`]) || this.isAdmin,
        level:
          this.getPermissions([`base_config.view_supplierlevel`]) ||
          this.isAdmin,
        product: this.getPermissions([`product.view_product`]) || this.isAdmin,
        terms: this.getPermissions([`base_config.view_term`]) || this.isAdmin
      }
    }
  },
  methods: {
    changeRoute(item) {
      this.$router.push({
        name: 'supplier-pk',
        params: { pk: this.codification({ code: item.pk }) }
      })
    },
    changeRouteEdit(item) {
      this.$router.push({
        name: 'supplier-pkEdit',
        params: { pk: this.codification({ code: item.pk }) }
      })
    },
    showHistory(item) {
      this.toEdit = item === undefined ? null : item
      this.dialog = true
    },
    /**
     * setCompany
     * Método para asignar la compañía a la que pertenece el usuario al
     * filtro de la empresa de la tabla
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    setCompany() {
      if (this.myCompanies.length > 0) {
        this.firstCompany = this.myCompanies[0].id
      }
    }
  },
  created() {
    this.setCompany()
  }
}
</script>
